@import 'assets/styles/_imports';

.share-bet {
	height: 100%;
	
	.bet {
		max-width: unset;
		border-radius: 8px;
		border: 1px solid #F0F0F0;
		margin-bottom: 16px;

		&__heading {
			background: rgba(240, 240, 240, 1);
			border-bottom: 1px solid #F0F0F0;
			border-radius: 8px 8px 0 0;
			padding: 12px;
		}

		&__content {
			padding: 0 12px 16px;
		}
	}

	&__stub {
		margin-top: 69px;
	}

	&__stub-content {
		text-align: center;
		max-width: 316px;
		width: 100%;
		margin: 0 auto;
	}

	&__stub-pic {
		margin: 0 auto;
		width: 180px;
		height: 180px;
		margin-bottom: 23px;
		background-size: cover;
	}

	&__stub-img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__stub-title,
	&__stub-text {
		font-size: 14px;
		line-height: 24px;
		color: $textPrimary;
	}

	&__stub-title {
		font-weight: 700;
		margin-bottom: 5px;
	}

	&__stub-text {
		font-weight: 500;
	}

	&__axis-y {
		width: 100%;
		height: 100%;
		overflow: hidden scroll;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
		
		.preloader{
			position: static;
			height: auto;
		}
	}

	&__error{
		text-align: center;
	}
}
